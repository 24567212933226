export const azTranslation = {
  translation: {
    header: {
      about: "Haqqımızda",
      services: "Xidmətlərimiz",
      legislation: "Qanunvericilik",
      certificates: "Sertifikatlar",
      faq: "FAQ",
      contact: "Əlaqə",
      search: "Buradan axtarın"
    },
    home: {
      content: "2017-ci ildən etibarən fəaliyyətə başlayan ”MİNOR ВUSINESS GROUP” MMC sahibkarlıq subyektlərinə hüququn bir çox sahəsi, həmçinin lisenziyaların alınması...",
      moreBtn: "Ətraflı",
      contactBtn: "Bizimlə əlaqə",
      title1: "Lisenziyaların alınmasında hüquqi xidmət!",
      title2: "Hüquqlarınızı peşəkarlara həvalə edin!",
      serviceNum: "Xidmət sayı",
      customerNum: "Məmnun müştəri",
      collaboratorNum: "Əməkdaş"
    },
    about: {
      title2: "Minor Şirkətlər Qrupu MMC-nin nəznində daha 2 şirkət fəaliyyət göstərməkdədir:",
      title3: "Əməkdaşlıq etdiyimiz şirkətlərdən bizə gələn tövsiyyə məktubları:",
      title4: "Tədbirlərimiz:",
      btn: "Ətraflı",
    },
    services: {
      title: "Xidmətlərimiz",
      backBtn: "Geriyə",
      categories: "Kateqoriyalar",
      contactBtn: "Bizimlə əlaqə"
    },
    certificates: {
      head: "Sertifikatlar",
      other: "Digər sertifikatlar"
    },
    faq: {
      title: "Tez-tez verilən suallar",
      btn: "Bizimlə əlaqə"
    },
    contact: {
      info: {
        title: "Bizimlə əlaqə",
        addressName: "Ünvan",
        address: "M.Araz küç. 109, Atatürk prospekti, Bakı, Azərbaycan",
        mailName: "E-mail",
        phoneName: "Tel",
        btn: "Bizimlə əlaqə"
      },
      form: {
        backBtn: "Geriyə",
        contactBtn: "Bizimlə əlaqə",
        toast: "Zəhmət olmasa bütün məlumatları qeyd edin",
        placeholder: {
          name: "Ad, Soyad",
          number: "Əlaqə nömrəsi",
          select: "Xidmət növü"
        },
        sentBtn: "Göndərildi",
        sendBtn: "Göndər"
      }
    }
  }
}