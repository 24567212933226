import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../sections/Main.css";
import Slider1 from "../../../assets/images/slider-img1.png";
import SliderMobile from "../../../assets/images/mobile-slider-img.png";
import Slider2 from "../../../assets/images/slider-img2.png";
import Slider3 from "../../../assets/images/slider-img3.jpg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";

import { Mousewheel, Pagination } from "swiper/modules";

const Main = () => {
  const { setActiveSlide } = useOutletContext();

  const { t } = useTranslation();
  return (
    <div>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        spaceBetween={30}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
        speed={1000}
        onSlideChange={(swiper) => {
          setActiveSlide(swiper.activeIndex);
        }}
      >
        <div className="container-header">
          <SwiperSlide className="swiper-content swiper-first-active">
            <div className="overlay-container">
              <img
                src={Slider1}
                className="rotated-image desktop-image"
                alt=""
                loading="lazy"
              />
              <img
                src={SliderMobile}
                className="rotated-image mobile-image"
                alt=""
                loading="lazy"
              />
              <div className="overlay"></div>
              <div className="slider-about">
                <div className="text">
                  <h1>
                    Minor <span>business group</span>
                  </h1>
                </div>
                <div className="slider-about-desc">
                  <p>{t("home.content")}</p>
                </div>
                <div className="slider-buttons">
                  <NavLink to="/about"> {t("home.moreBtn")}</NavLink>
                  <NavLink to="/contact" className="slider-contact-button">
                    {t("home.contactBtn")}
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="swiper-content">
            <div className="overlay-container-light">
              <img src={Slider2} alt="" loading="lazy" />
              <div className="overlay-light"></div>
              <div className="slider-about">
                <div className="text-slider2">
                  <h2>{t("home.title1")}</h2>
                </div>
                <div className="slider-buttons-slider2">
                  <NavLink to="/about">{t("home.moreBtn")}</NavLink>
                  <NavLink to="/contact" className="slider-contact-button">
                    {t("home.contactBtn")}
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-content">
            <div className="overlay-container-light">
              <img src={Slider3} alt="" loading="lazy" />
              <div className="overlay-light"></div>
              <div className="slider-about">
                <div className="text-slider2">
                  <h2>{t("home.title2")}</h2>
                </div>
                <div className="slider-counter">
                  <div className="counter-text">
                    <h3>780+</h3>
                    <span>{t("home.serviceNum")}</span>
                  </div>
                  <div className="counter-text">
                    <h3>600+</h3>
                    <span>{t("home.customerNum")}</span>
                  </div>
                  <div className="counter-text">
                    <h3>37</h3>
                    <span>{t("home.collaboratorNum")}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </div>
  );
};

export default Main;
