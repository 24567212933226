import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/minor-group-logo.png";
import Search from "../../assets/images/search.png";
import LogoWhite from "../../assets/images/minor-main-logo.png";
import { IoSearch } from "react-icons/io5";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { IoMenu } from "react-icons/io5";
import { Drawer, Radio, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../api/customApi";
import { LangContext } from "../../context/LangContext";
import "../Header/style.css";

const Header = ({ activeSlide }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [openModal, setOpenModal] = useState(false);
  const [color, setColor] = useState(false);
  const { lang, changeLanguage } = useContext(LangContext);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const isFirstSlide = activeSlide === 0;
  const footerColor = isFirstSlide ? "white" : "black";

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const changeColor = () => {
    if (window.scrollY >= 40) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const languageItems = [
    {
      key: "az",
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            changeLanguage("az");
          }}
        >
          AZ
        </a>
      ),
    },
    {
      key: "en",
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            changeLanguage("en");
          }}
        >
          EN
        </a>
      ),
    },
    {
      key: "ru",
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            changeLanguage("ru");
          }}
        >
          RU
        </a>
      ),
    },
  ];

  window.addEventListener("scroll", changeColor);
  const { data: services } = useQuery({
    queryKey: ["servicesItems", lang],
    queryFn: () => getApi("/services"),
  });
  const { data: legislations } = useQuery({
    queryKey: ["legislations", lang],
    queryFn: () => getApi("/legislations"),
  });

  const serviceItems = Array.isArray(services)
    ? services?.map((service) => ({
        key: service?.id,
        label: (
          <a href={`/services/${service?.slug}`}>{service?.title?.[lang]}</a>
        ),
      }))
    : [];

  const legislationItems = Array.isArray(legislations)
    ? legislations?.map((legislation) => ({
        key: legislation?.id,
        label: (
          <a href={legislation?.url} target="_blank" rel="noopener noreferrer">
            {legislation?.title?.[lang]}
          </a>
        ),
      }))
    : [];

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    onClose();
  }, [location]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  return (
    <div className={color ? "header-wrapper headerBg" : "header-wrapper"}>
      <div className="container-header">
        <div className="header-logo">
          <NavLink to="">
            {isFirstSlide && isMainPage ? (
              <img
                src={LogoWhite}
                alt="White Logo"
                className="header-logo-img"
              />
            ) : (
              <img
                src={Logo}
                alt="Black Logo"
                className="header-logo-img"
                loading="lazy"
              />
            )}
          </NavLink>
        </div>
        <div className="header-links">
          <NavLink
            to="/about"
            className={(goPage) => (goPage.isActive ? "active" : "")}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            {t("header.about")}
          </NavLink>
          <Dropdown
            menu={{
              items: serviceItems,
            }}
            overlayStyle={{ height: 500, overflow: "auto" }}
            overlayClassName="centered-dropdown"
            trigger={["hover"]}
          >
            <NavLink
              to="/services"
              className={(goPage) => (goPage.isActive ? "active" : "")}
              style={!isMainPage ? { color: "black" } : { color: footerColor }}
            >
              {t("header.services")}
              <Space>
                <DownOutlined
                  style={
                    !isMainPage ? { color: "black" } : { color: footerColor }
                  }
                />
              </Space>
            </NavLink>
          </Dropdown>

          <Dropdown
            menu={{
              items: legislationItems,
            }}
            overlayStyle={{ height: 500, overflow: "auto" }}
            overlayClassName="l-centered-dropdown"
            trigger={["hover"]}
          >
            <a
              style={
                !isMainPage
                  ? { color: "black", cursor: "pointer" }
                  : { color: footerColor, cursor: "pointer" }
              }
            >
              {t("header.legislation")}
              <Space>
                <DownOutlined
                  style={
                    !isMainPage ? { color: "black" } : { color: footerColor }
                  }
                />
              </Space>
            </a>
          </Dropdown>
          <NavLink
            to="/certification"
            className={(goPage) => (goPage.isActive ? "active" : "")}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            {t("header.certificates")}
          </NavLink>
          <NavLink
            to="/faq"
            className={(goPage) => (goPage.isActive ? "active" : "")}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            {t("header.faq")}
          </NavLink>
          <NavLink
            to="/contact"
            className={(goPage) => (goPage.isActive ? "active" : "")}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            {t("header.contact")}
          </NavLink>
        </div>
        <div className="language-search-content">
          <div className="language-wrapper">
            <ul className="languages">
              {["az", "en", "ru"].map((lng) => (
                <li key={lng}>
                  <a
                    className={lang === lng ? "active-lang" : ""}
                    onClick={() => changeLanguage(lng)}
                    style={
                      !isMainPage ? { color: "black" } : { color: footerColor }
                    }
                  >
                    {lng.toUpperCase()}
                  </a>
                </li>
              ))}
            </ul>
            <div className="dropdown-language">
              <Dropdown
                menu={{
                  items: languageItems,
                }}
                overlayStyle={{ height: "auto", overflow: "hidden" }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    className="active-language"
                    style={
                      !isMainPage ? { color: "black" } : { color: footerColor }
                    }
                  >
                    {lang.toUpperCase()}
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          <div
            className="search"
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            <IoSearch onClick={() => setOpenModal(true)} />
          </div>
          <Modal
            centered
            open={openModal}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
            width={1000}
            closable={true}
          >
            <div>
              <input
                type="text"
                placeholder={t("header.search")}
                className="modal-input"
              />
              <IoSearch className="modal-search-icon" />
            </div>
          </Modal>
          <IoMenu
            className="menu-icon"
            onClick={showDrawer}
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          />
          <Space>
            <Radio.Group value={placement} onChange={onChange}></Radio.Group>
          </Space>
          <Drawer
            title={
              <NavLink to="/">
                <img src={LogoWhite} alt="Logo" style={{ width: "180px" }} />
              </NavLink>
            }
            placement={placement}
            closable={true}
            onClose={onClose}
            open={open}
            key={placement}
          >
            <div className="header-links-menu">
              <NavLink
                to="/about"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.about")}
              </NavLink>
              <NavLink
                to="/services"
                className={(goPage) => (goPage.isActive ? "active" : "")}
                onClick={showDrawer}
              >
                {t("header.services")}
              </NavLink>
              <a
                onClick={toggleDropdown}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className={`fa-solid ${
                    isOpen ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                  style={{ fontSize: 24, marginRight: 5 }}
                ></i>
                {t("header.legislation")}
              </a>
              <div
                className="dropdown-content"
                style={{
                  display: isOpen ? "block" : "none",
                  backgroundColor: "transparent",
                  width: "90%",
                  height: "370px",
                  overflowY: "auto",
                  direction: "rtl",
                  WebkitOverflowScrolling: "touch"
                }}
              >
                <ul
                  className="menu-list"
                  style={{ listStyleType: "none", padding: 0, margin: 0 }}
                >
                  {legislationItems?.map((item) => (
                    <li
                      key={item.key}
                      style={{ padding: "0px 0", cursor: "pointer" }}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </div>
              <NavLink
                to="/certification"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.certificates")}
              </NavLink>
              <NavLink
                to="/faq"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.faq")}
              </NavLink>
              <NavLink
                to="/contact"
                className={(goPage) => (goPage.isActive ? "active" : "")}
              >
                {t("header.contact")}
              </NavLink>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
