import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "../ContactForm/contactform.scss";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getApi, postApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { Select, Space } from "antd";
import { LangContext } from "../../../context/LangContext";
import { useTranslation } from "react-i18next";
import long from "../../../assets/images/png/long-arrow.png";
import mobileImg from "../../../assets/images/mobile-contact-img.png";
const { Option } = Select;

const ContactForm = () => {
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();

  const { data: options = [], isLoading: optionsLoading } = useQuery({
    queryKey: ["contact-services", lang],
    queryFn: () => getApi("/contact-services"),
  });

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    contact_service_id: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const mutation = useMutation({
    mutationFn: (data) => postApi("/contacts", data),
    onSuccess: () => {
      setFormData({
        name: "",
        number: "",
        contact_service_id: "",
      });
      setIsSubmitted(true);
      setIsButtonDisabled(true);

      setTimeout(() => {
        setIsSubmitted(false);
        setIsButtonDisabled(false);
      }, 2000);
    },
    onError: (err) => {
      console.error(err.res?.data || err.message);
    },
  });

  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.number || !formData.contact_service_id) {
      toast.error(t("contact.form.toast"));
      return;
    }
    mutation.mutate(formData);
  };

  return (
    <div className="contact-form-wrapper">
      <div className="mobile-contact-image">
        <div className="mobile-contact-overlay"></div>
        <img src={mobileImg} alt="" />
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="contact-form-image"></div>
      <div className="contactform-content">
        <div className="container">
          <NavLink to="/contact" className="contact-back-btn">
            <img
              className=""
              width={41}
              height={41}
              src={long}
              alt="arrow icon"
            />
            {t("contact.form.backBtn")}
          </NavLink>
          <div className="main-p">
            <p>{t("contact.form.contactBtn")}</p>
          </div>
          <form onSubmit={handleSubmit} className="contact-message">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) => handleChange(e.target.value, "name")}
              placeholder={t("contact.form.placeholder.name")}
            />
            <input
              type="text"
              name="number"
              value={formData.number}
              onChange={(e) => handleChange(e.target.value, "number")}
              placeholder={t("contact.form.placeholder.number")}
            />
            <Space wrap>
              <Select
                className="select"
                value={formData.contact_service_id || undefined}
                onChange={(value) => handleChange(value, "contact_service_id")}
                placeholder={t("contact.form.placeholder.select")}
                loading={optionsLoading}
              >
                {optionsLoading ? (
                  <Spinner />
                ) : (
                  options?.map((option) => (
                    <Option key={option?.id} value={option?.id}>
                      {option?.title?.[lang] ||
                        option?.title?.az ||
                        t("contact.form.placeholder.defaultOption")}
                    </Option>
                  ))
                )}
              </Select>
            </Space>

            <div className="contact-button">
              <button type="submit" disabled={isButtonDisabled}>
                {isSubmitted
                  ? t("contact.form.sentBtn")
                  : t("contact.form.sendBtn")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
