// src/context/LangContext.js
import React, { createContext, useState, useEffect } from 'react';
import i18n from '../i18n'; // Adjust the path according to your project structure

export const LangContext = createContext();

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState(localStorage.getItem('language') || 'az');

  const changeLanguage = (newLang) => {
    setLang(newLang);
    localStorage.setItem('language', newLang);
    i18n.changeLanguage(newLang);
  };

  // Ensure i18n language is set correctly on initial load
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <LangContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LangContext.Provider>
  );
};
