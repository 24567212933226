import React from "react";
import "../Contact/contact.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-wrapper">
      <div className="contact-main-image"></div>
      <div className="contact-content">
        <div className="container">
          <div className="main-p">
            <p>{t("contact.info.title")}</p>
          </div>
          <div className="contact-details">
            <div className="contact-item">
              <span>{t("contact.info.addressName")}:</span>
              <p>{t("contact.info.address")}</p>
            </div>
            <div className="contact-item">
              <span>{t("contact.info.mailName")}:</span>
              <NavLink to="mailto:info@minorgroup.az">
                info@minorgroup.az
              </NavLink>
            </div>
            <div className="contact-item">
              <span>{t("contact.info.phoneName")}: </span>
              <NavLink to="tel:(+994) 70 255 09 20" className="contact-phone">
                (+994) 70 255 09 20
              </NavLink>
            </div>
          </div>
          <div className="contact-button">
            <NavLink to="/contact-form">
              <button>{t("contact.info.btn")}</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
