export const ruTranslation = {
  translation: {
    header: {
      about: "Oколо",
      services: "Услуги",
      legislation: "Законодательство",
      certificates: "Сертификаты",
      faq: "ФАГ",
      contact: "Контакт",
      search: "Искать здесь"
    },
    home: {
      content:
        "ООО «МИНОР БИЗНЕС ГРУП», работающее с 2017 года, обеспечивает субъектам предпринимательства многие отрасли права, а также получение лицензий...",
      moreBtn: "Подробный",
      contactBtn: "Связаться с нами",
      title1: "Юридические услуги по получению лицензий!",
      title2: "Доверьте свои права профессионалам!",
      serviceNum: "Сервисный номер",
      customerNum: "Довольный клиент",
      collaboratorNum: "Соавтор",
    },
    about: {
      title2: "В составе ООО «Группа малых компаний» действуют еще 2 компании:",
      title3: "Рекомендательные письма от наших компаний-партнеров:",
      title4: "Наши мероприятия:",
      btn: "Подробный",
    },
    services: {
      title: "Наши услуги",
      backBtn: "Назад",
      categories: "Категории",
      contactBtn: "Связаться с нами"
    },
    certificates: {
      head: "Сертификаты",
      other: "Другие сертификаты"
    },
    faq: {
      title: "Часто задаваемые вопросы",
      btn: "Связаться с нами",
    },
    contact: {
      info: {
        title: "Связаться с нами",
        addressName: "Адрес",
        address: "ул. М.Араза Проспект Ататюрка, 109, Баку, Азербайджан",
        mailName: "E-mail",
        phoneName: "Тел",
        btn: "Связаться с нами",
      },
      form: {
        backBtn: "Назад",
        contactBtn: "Связаться с нами",
        toast: "Пожалуйста, введите всю информацию",
        placeholder: {
          name: "Имя, Фамилия",
          number: "Контактный номер",
          select: "Тип услуги",
        },
        sentBtn: "Отправил",
        sendBtn: "Отправлять",
      },
    },
  },
};
