import React, { useContext } from "react";
import "./serviceSide.scss";
import Service from "../../../components/Services/Service";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";
import { useTranslation } from "react-i18next";

// Function to slugify service titles

const ServiceSide = () => {
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();

  const { data: categories, isLoading: categoriesLoading } = useQuery({
    queryKey: ["services", lang],
    queryFn: () => getApi("/services"),
  });

  const setService = (az, en, ru) => {
    localStorage.setItem(
      "minor_service_name",
      JSON.stringify({ az: az, en: en, ru: ru })
    );
  };

  return (
    <div className="service_side">
      <h1 className="service_side_head_label">{t("services.title")}</h1>
      {categoriesLoading && <Spinner />}
      <div className="service_side_container">
        {
          categories && categories.map((service) => {
            const slug = service.slug;
            return (
              <Link
                to={`/services/${slug}`}
                className="service_side_container_link"
                onClick={() =>
                  setService(service.title?.az, service.title?.en, service.title?.ru)
                }
                key={service.id}
              >
                <Service label={service.title?.[lang]} />
              </Link>
            )
          })}
      </div>
    </div>
  );
};

export default ServiceSide;
